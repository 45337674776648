(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-fraction/assets/utils/track-fractions.js') >= 0) return;  svs.modules.push('/components/payment/service-fraction/assets/utils/track-fractions.js');

'use strict';

const {
  analytics
} = svs.components;
const {
  logger
} = svs.components.payment.common;
const {
  getProdName
} = svs.components.lbUtils.sportProducts;


const trackFractions = async payableItemsData => {
  const {
    payable
  } = (payableItemsData === null || payableItemsData === void 0 ? void 0 : payableItemsData[0]) || {};
  if (!payable) {
    logger.info('Payment tracking: No fraction data to track.');
    return;
  }
  const productName = getProdName(payable.productId);
  analytics.trackEvent({
    category: 'Tillsammans',
    action: 'Reserve share',
    opt_label: "".concat(productName, "-").concat(payable.groupId)
  });
};
setGlobal('svs.components.payment.serviceFraction.utils.trackFractions', trackFractions);

 })(window);