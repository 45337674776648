(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-fraction/assets/fraction-service.js') >= 0) return;  svs.modules.push('/components/payment/service-fraction/assets/fraction-service.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  PaymentService
} = svs.components.payment.servicePayment;
const {
  isFraction
} = svs.components.payment.createFraction.utils;
const {
  PaymentError
} = svs.components.payment.common.error.PaymentError;
const {
  services,
  utils
} = svs.components.payment.serviceFraction;
const {
  account_balance: accountBalance
} = svs.components;
const {
  userSession
} = svs.core;

const createPaymentDTO = (fraction, fractionResponse) => Object.freeze({
  get cost() {
    return parseInt(fraction.price, 10);
  },
  get balance() {
    return fractionResponse === null || fractionResponse === void 0 ? void 0 : fractionResponse.accountBalance;
  },
  get transactionId() {
    return null;
  }
});

const assertInstanceOfFraction = fraction => {
  if (!isFraction(fraction)) {
    throw new PaymentError({
      clientCode: PaymentError.ClientErrorCodes.NOT_FRACTION
    });
  }
};

var _accountBalance = new WeakMap();
var _totalCost = new WeakMap();
class FractionService extends PaymentService {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _accountBalance, null);
    _classPrivateFieldInitSpec(this, _totalCost, null);
  }
  assertPayable() {
    for (var _len = arguments.length, items = new Array(_len), _key = 0; _key < _len; _key++) {
      items[_key] = arguments[_key];
    }
    for (const fraction of items) {
      super.assertPayable(fraction);
      assertInstanceOfFraction(fraction);
    }
  }
  async processRequest(fraction) {
    var _paymentDTO$balance;
    const paymentDTO = await services.postFraction(fraction);
    _classPrivateFieldSet(_accountBalance, this, (_paymentDTO$balance = paymentDTO.balance) !== null && _paymentDTO$balance !== void 0 ? _paymentDTO$balance : _classPrivateFieldGet(_accountBalance, this));
    _classPrivateFieldSet(_totalCost, this, _classPrivateFieldGet(_totalCost, this) + paymentDTO.cost);
    return createPaymentDTO(fraction, paymentDTO);
  }
  onPaymentDone(paidWagersData) {
    const lastPaidItem = paidWagersData.slice(-1).pop();
    const hasNewBalance = Boolean(lastPaidItem);

    if (hasNewBalance && userSession.hasRole(userSession.roles.PLAYER)) {
      accountBalance.set(lastPaidItem.dto.balance);
    }
  }
  trackPayments(fractionData, userContext) {
    utils.trackFractions(fractionData, userContext);
  }
}
setGlobal('svs.components.payment.serviceFraction.FractionService', FractionService);

 })(window);